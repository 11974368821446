
.section-team {
    margin-bottom: 100px;
    .see-more {
        display: none;
    }
    .section-team-wrapper {
        position: relative;
        padding-bottom: 200px;
        .section-team-img {
            z-index: 9;
            position: relative;
            width: 232px;
            height: 303px;
            -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
            clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
            img {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                right: 0;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }
        .section-team-content {
            -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
            clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
            z-index: 1;
            position: absolute;
            right: 20%;
            bottom: 12%;
            width: 210px;
            height: 273px;
            background: linear-gradient(130.91deg, #145571 8.77%, #2BB6BD 125.18%);
            display: flex;
            flex-direction: column;
            padding-top: 100px;
            justify-content: center;
            align-content: space-around;
            align-items: center;
            h4 {
                color: $color-white;
                font-size: 24px;
                margin-bottom: 10px;
            }
            p {
                font-size: 18px;
                color: $color-white;
                margin-bottom: 10px;
                text-align: center;
            }
        }
    }
}

@media (max-width: $breakpoint-mobile) {
    .section-team {
        margin-bottom: 100px;
        .see-more {
            display:inline-block;
            margin: 0 auto;
            text-align:center;
        }
        .section-team-wrapper {
            position: relative;
            padding-bottom: 140px;
            // margin-left: 2vw;
            width: 100%;
            .section-team-img {
                width: 130px;
                height: 171px;
            }
            .section-team-content {
                width: 139px;
                height: 180px;
                margin-left: 12px;
                margin-top: 84px;
                top: 0;
                left: 0;
                padding-top: 80px;
                h4 {
                    font-size: 18px;
                    margin-bottom: 5px;
                }
                p {
                    font-size: 14px;
                    margin-bottom: 5px;
                    line-height: 16px;
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }
    }
    
}