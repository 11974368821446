
.section-s-text-2 {
    margin-bottom: 100px;
    .slick-slide {
        padding: 0;
    }
    .slick-dots {
        bottom: -50px;
        
        li.slick-active {
            button {
                
            background: $color-blue-darkest;
            }
        }
        li {
            width: 33%;
            margin: 0;
        }
        button {
            width: 100%;
            height: 5px;
            background-color: #EBF0F1;
        }
    }
    h2 {
        font-size: 48px;
        line-height: 55px;
        color: #145571;
        margin-bottom: 82px;
        width: 70%;
    }
    .row > div {
        display: flex;
    }
    .section-s-text-2-row {
        .section-s-text-2-col {
            background-size: auto;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            margin-bottom: 100px;
            background-position-x: 1px;
            img {   
                height: 85px;
            }

            p {
                padding-left: 25px;
                font-size: 18px;
                color:$color-grey-p
            }
        }
    }
}


@media (max-width: $breakpoint-tablet) {
    .section-s-text-2 {
        h2 {
            width: 100%;
            font-size: 36px;
            line-height: 45px;
            margin-bottom: 30px;
        }
        &.img-bg-line {
            background-size: contain;
        }
        .section-s-text-2-row {
            .section-s-text-2-col {
                margin-bottom: 45px;
            }
        }
    }
}
