.section-video {
    position: relative;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right -3% top 65%;
    margin-bottom: 100px;
    h2 {
        font-size: 48px;
        line-height: 55px;
        color: #145571;
        width: 70%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 45px;
    }
}


@media (max-width: $breakpoint-tablet) {
    .section-video {
        margin-bottom: 100px;
        background-size: contain;
        h2 {
            width: 100%;
            font-size: 36px;
            line-height: 45px;
            margin-bottom: 30px;
        }
        iframe {
            max-height: 230px;
        }
    }
}
@media (max-width: 500px) {
    .section-video {
        background-position: right -50% top 65%;
    }
}