.slick-slide {
    height: inherit !important;
}
.site-header-carousel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    .left-section {
      background: linear-gradient(130.91deg, #145571 8.77%, #2BB6BD 125.18%);
      height: 100%;
      img {
        width: 100%;
        height: 600px;
        object-fit: cover;
      }
      .left-section-container {
        position: absolute;
        top: 15%;
        left: 15%;
        // top: 45%;
        // left: 55%;
        // -webkit-transform: translate(-50%, -50%);
        // transform: translate(-50%, -50%);
        color: $color-white;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        width: 75%;

        h2 {
          font-size: 4.4vw;
          margin-bottom: 40px;
        }
        p {
          font-family: 'Sofia-Thin';
          font-size: 18px;
          margin-bottom: 15px;
          line-height: 25px;
          font-weight: bolder;
        }
        a {
          font-size: 20px;
          color: $color-white;
          position: relative;
          &:hover {
            &::after {
              transform: translateX(-140%);
            }
          }
          &::after {
            content: '';
            width: 40%;
            background-color: $color-orange;
            height: 3px;
            position: absolute;
            right: 0;
            bottom: -4px;
            transition: transform 0.3s ease;
            transform: translateX(0px);
          }
        }
      }
    }
    .right-section {
      img {
        width: 100%;
        height: 650px;
        object-fit: cover;
      }
    }
    .site-header-carousel-wrapper {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      position: relative;
      overflow: hidden;
    }
  }
  
  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;
    position: absolute;
    bottom: 50px;
    z-index: 999;
    left: 0;
    right: 0;
      
          li {
              margin: 0 0.25rem;
          }
      
          button {
              display: block;
              width: 30px;
              height: 3px;
              padding: 0;
              border: none;
              background-color: #EBF0F1;
              text-indent: -9999px;
              cursor: pointer;
          }
      
          li.slick-active button {
        
              background: linear-gradient(140.8deg, #FF8000 4.79%, #FDE74C 135.06%);
          }
      
  }
  

.slick-active .left-section-container {
  animation-name: fadeNavBotttom2;
  animation-duration: 1s;
  opacity: 1;
}



@media (max-width: $breakpoint-mobile) {
  .site-header-carousel {
    margin-bottom: 100px;
    .right-section {
      position: absolute;
      right: 0;
      bottom: -25%;
      width: 59%;
      height: 48%;
      .slick-dots {
        bottom: 0;
      }
      img {
        height: 200px;
      }
    }
    .left-section {
      img {
        height: 420px;
      }

    }
  }
}
@media (max-width:460px) {
  .site-header-carousel {
    .left-section {
      .left-section-container {
        top: 0;
        left: 0;
        width: 100%;
        padding:  0 15px;
        h2 {
          font-size: 28px;
          margin-top: 50px;
          margin-bottom: 10px;
        }
        p {
          width: 100%;
        }
      }
    }
  }
}
