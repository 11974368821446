.footer-form-input-container { 
    position:relative; 
    margin-bottom:45px; 
    textarea {
        resize: none;
    }
    input,
    textarea {
        font-size:16px;
        padding: 20px 5px 10px;
        display:block;
        width:100%;
        border:none;
            &:focus {
                outline:none;
            }
        }
    /* LABEL ======================================= */
    label {
    color:$color-blue-darkest; 
    font-size:18px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:15px;
    top:15px;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
     
    }
    &.error {
        label {
            color: $color-red-e !important;
        }
    }
        
    /* active state */
    input:focus ~ label,
    input:valid ~ label,
    textarea:focus ~ label,
    textarea:valid ~ label 		{
        top:2px;
        font-size:14px;
        color:$color-blue-gree;
    }
        
    /* BOTTOM BARS ================================= */
    .bar { 
        position:relative; display:block; width:100%;
     }

     &.error {
        .bar:before, .bar:after {
            background:$color-red-e; 
        }
    }
    .bar:before, .bar:after 	{
    content:'';
    height:2px; 
    width:0;
    bottom:0px; 
    position:absolute;
    background:$color-blue-darkest; 
    transition:0.5s ease all; 
    -moz-transition:0.5s ease all; 
    -webkit-transition:0.5s ease all;
    }
    .bar:before {
    left:50%;
    }
    .bar:after {
    right:50%; 
    }
        
    /* active state */
    input:focus ~ .bar:before,
    input:focus ~ .bar:after,
    textarea:focus ~ .bar:before,
    textarea:focus ~ .bar:after {
    width:50%;
    }
    
    /* HIGHLIGHTER ================================== */
    .highlight {
    position:absolute;
    // height:60%; 
    width:100%; 
    top:25%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
    }
        
    /* active state */
    input:focus ~ .highlight,
    textarea:focus ~ .highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
    }
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
    from { background:$color-blue-darkest; }
to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
    from { background:$color-blue-darkest; }
to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
    from { background:$color-blue-darkest; }
to 	{ width:0; background:transparent; }
}