.site-footer-form {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 55px;
  padding-bottom: 75px;
  background-image: url("../../images/footer-form-background.png");
  margin-bottom: -100px;

  h2 {
    text-align: center;
    font-size: 36px;
    font-family: 'Sofia-Cond-SemiBold';
    color: $color-white;
    margin-bottom: 60px;
  }

  .footer-form-input-container {
    margin-right: 15px;
    margin-bottom: 24px;
 
    input {
      width: 100%;
      height: 48px;
      color: $color-blue-darkest;
      padding-left: 15px;
      top: 15px;
      font-size: 18px;
    }
    &.error {
      textarea,input {
        border: 1px solid $color-red-e;
      }
    }

    textarea {
      width: 100%;
      height: 120px;
      color: $color-blue-darkest;
      padding-left: 15px;
      top: 15px;
      font-size: 18px;
    }
  }

  .btn-form-wrapper {
    text-align: center;
  }

  .footer-form-check-section {
    margin-bottom: 18px;
  }
  .help-block {
    font-size: 12px;
    line-height: 12px;
    color: $color-white;
    margin-left: 5px;
    margin-top: 5px;
    position: absolute;
  }
  .checkbox-label {
    .help-block {
      left: 24px;
      margin-top: 0;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .site-footer-form {
    .footer-form-input-container {
      margin-right: 0;
    }
  }

  .site-footer-section-pages-links {
    margin-bottom: 15px;
  }

  .site-footer-pages-certification ul li {
    margin-right: 18px;
  }

  .footer-section-bootom-container {
    padding-top: 30px;
    position: relative;

    .footer-section-bootom-list {
      position: absolute;
      top: -5px;
      width: 100%;
      display: block;

      li {
        width: 50%;
        margin: 0 !important;
      }
    }

    div {
      width: 50%;
    }
  }

}