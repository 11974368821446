

.section-rack-unit {
    
    .slick-list{padding:0 20% 0 0 !important;}
    .slick-slide {
        padding: 0;
    }
    .slick-dots {
        bottom: -50px;
        
        li.slick-active {
            button {
                
            background: $color-blue-darkest;
            }
        }
        li {
            width: 33%;
            margin: 0;
        }
        button {
            width: 100%;
            height: 5px;
            background-color: #EBF0F1;
        }
    }
    
    margin-bottom: 100px;
    .rack-wrapper-all {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .rack-border-wrapper {
        flex:1;
        border: 1px solid #E5E5E5;
    }
    h2 {
        font-size: 48px;
        line-height: 55px;
        color: $color-blue-darkest;
        margin-bottom: 40px;
        width: 70%;
    }
    h3 {
        color: $color-white;
        font-size: 36px;
    }
    .header-rack {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .content-rack {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        img {
            object-fit: cover;
            max-width: 200px;
            max-height: 390px;
            padding: 30px 0 15px;
        }
    }
    .list-rack {
        border-top: 1px solid #E5E5E5;
        ul {
            padding: 15px 0;
            li {
            position: relative;
            font-size: 18px;
            color: $color-grey-p;
            line-height: 25px;
            padding: 0 25px 0 55px;
            margin-bottom: 15px;
            font-family: 'Sofia-Regular';
                &::before {
                    position: absolute;
                    content: "";
                    transform: rotate(45deg) scale(1);
                    opacity: 1;
                    left: 25px;
                    top: 6px;
                    width: 5px;
                    height: 10px;
                    border: solid #FF8000;
                    border-width: 0 2px 2px 0;
                    background-color: transparent;
                    border-radius: 0;
                }
            }
        }
    }

    .quarter-rack-wrapper {
        .header-rack {
            background-color: $color-4-rack;
        }
    }
    .half-rack-wrapper {
        .header-rack {
            background-color: $color-2-rack;
        }
    }
    .whole-rack-wrapper {
        .header-rack {
            background-color: $color-1-rack;
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .section-rack-unit {
        margin-bottom: 100px;

        h2 {
            width: 100%;
            font-size: 36px;
            line-height: 45px;
        }
    }
}
