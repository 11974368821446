/* START HEADER */

.sticky{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 10rem 8rem rgba(0, 0, 0, 0.07),
  0px 2.2rem 1.8rem rgba(0, 0, 0, 0.04), 0px 0.7rem 0.5rem rgba(0, 0, 0, 0.03);
}
.site-header-navigation {
    display: flex;
    align-items: center;
    height: 100px;
    background-color: $color-white;
    z-index: 99999999;
    a {
      color: $color-blue-darkest;
    }

    .site-header-mobile-wrapper {
      display: flex;
      width: 100%;
      position: relative;
      align-items: center;


      .site-header-navigation-list>ul {
        display: flex;
        @media (min-width:1200px) {
          padding: 10px
        }
        li {
          margin-left: 40px;
          position: relative;

          a {
            font-size: 20px;
          }
        }
        &  > li:nth-child(3) > a {
          cursor: default;
        }
        &  > li:nth-child(2) > a {
          cursor: default;
        }

        .site-header-navigation-dropdown {
          display: none;
        }

      }

      .site-header-navigation-search {
        margin-left: auto;

        ul {
          display: flex;
          align-items: center;

          a {
            font-size: 18px;
          }

          .site-header-nav-search-list {
            margin-right: 25px;
          }

          .site-header-nav-lang-list {
            margin-right: 70px;
          }
        }

      }

    }

    .site-header-navigation-logo-wrapper {
      margin: 0 10px 0 40px;
      a {
        max-width: 202px;
        img {
          width: 100%;
        }
      }
    }

  }


  @media (min-width:1200px) {
    .search-icon {
      cursor: pointer;

      .circle {
        position: relative;
        display: block;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        z-index: 0;
        border: 3px solid #145571;
        transition: all 0.6s cubic-bezier(0.25, 1.7, 0.35, 0.8);
        z-index: 1;
        background-color: white;
      }

      .handle {
        position: absolute;
        background-color: #145571;
        left: 0px;
        width: 23px;
        height: 2px;
        display: block;
        transform: rotate(45deg);
        float: right;
        top: 11px;
        &:after {
          content: "";
          background-color: #145571;
          width: 23px;
          height: 2px;
          display: block;
          float: right;
          transition: all 0.6s cubic-bezier(0.25, 1.7, 0.35, 0.8);
        }
      }
    }


    .open {
      .circle {
        transform: scale(.02);
        transform-origin: 30% 40%;
        background: $color-blue-darkest;
        border-color:$color-blue-darkest;
      }
      .handle:after {
        transform-origin: center center;
        transform: rotate(90deg);
      }
    }

    .site-header-navigation {
      .site-header-navigation-search {
        .site-header-nav-lang-list {
          a {
            display: none;
          }
          a.lang-list-active {
            display: inline-block ;
          }
        }
      }

      .site-header-navigation-list {
        background-color: $color-white;
      }
      .site-header-nav-search-list {
        img {
          display: none;
        }
        .search-icon {
          position: absolute;
          right: 29px;
          top: 12px;
        }

      }
      .search-nav-form {

        position: absolute;
        left: 0;
        top: 0;
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: -1;
        input {
          padding-left: 1%;
          opacity: 0;
          position: absolute;
          border: none;
          display: block;
          font-size: 24px;
          color: $color-blue-darkest;
          width: 90%;
          outline: none;
          top: -5px;
          opacity: 1;
          font-weight: bold;
          padding-top: 5px;
          padding-bottom: 5px;
          &::placeholder {
            color: $color-blue-darkest;
            font-weight: bold;
            padding-left: 5px;
            opacity: 1;
          }
          &:-ms-input-placeholder {
            color: $color-blue-darkest;
            font-weight: bold;
            padding-left: 5px;
            opacity: 1;
          }
        }
      }
      &.open {
          .search-nav-form {
            z-index: 99;
            input {
              -webkit-animation: fadeNavTop 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
              animation: fadeNavTop 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
            }
          }

          .site-header-navigation-list {
            -webkit-animation: fadeNavBotttom 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
            animation: fadeNavBotttom 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
          }

      }
      &.not-open {
          .search-nav-form {
            input {
              -webkit-animation: fadeNavTop2 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
              animation: fadeNavTop2 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
            }
          }
          .site-header-navigation-list {
            -webkit-animation: fadeNavBotttom2 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
            animation: fadeNavBotttom2 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
          }
      }
    }

    .navigation-first-list img {
      display: none;
    }

    .site-header-nav-search-list input {
      display: none;
    }

    .site-header-navigation-list>ul>li:hover {
      .site-header-navigation-dropdown {
        display: block !important;
        position: absolute;
        text-align: left;
        z-index: 1;
        padding-top: 37px;
        left: 50% !important;
        right: auto !important;
        transform: translate(-50%, 0) !important;
        min-width: 370px;

        ul {
          padding: 25px;
          box-shadow: 0 5px 10px 2px rgb(0 0 0 / 20%);
          background-color: white;

          li {
            margin: 0 0 20px 0;

            &:last-child {
              margin: 0;
            }

            a {
              font-size: 20px;

              &:hover {
                color: $color-blue-gree;
              }
            }

            span {
              display: block;
              font-size: 14px;
              font-family: 'Sofia-Regular';
              margin-top: 5px;
              color: $color-grey;
            }
          }
        }
      }
    }

  }
  /* END HEADER */
