.section-s-image-0  + .section-s-image-0{
    margin-top: 300px;
}
.section-s-image-0 {
    margin-bottom: 200px;
    position: relative;
    margin-top: 0;
    
    .hex-image-corner-wrapper {
        position: absolute;
        width: 129px;
        height: 100%;
        z-index: 99;
        margin-left: -4vw;
        top: -5vw;
        .hex-image-corner-content {
            background-repeat: no-repeat;
            background-size: contain;
            height: 100%;
        }
    }
    &.hex-blue {    
        .hex-image-corner-wrapper {
            .hex-image-corner-content {
                background-image: url('../../images/hex-blue.svg');
            }
        }
    }
    &.hex-orange {
        .hex-image-corner-wrapper {
            .hex-image-corner-content {
                background-image: url('../../images/hex-orange.svg');
            }
        }
    }
    &.hex-left {    
        .hex-image-corner-wrapper {
            margin-left: -4vw;
            left: 0;
            top: -5vw;
        }
    }
    &.hex-right {
        .row {
            justify-content: flex-end;
            .col-lg-6 {
                margin-left: 0 !important;
                margin-right: 8.333333% !important;
            }
        }
        .hex-image-corner-wrapper {
            margin-right: -4vw;
            right: 0;
            position: absolute;
            top: 4vw;
            .hex-image-corner-content {
                background-position-y: bottom;
            }
        }
        .section-s-image-0-text-wrapper {            
            left: 11%;
            top: -30%;
            bottom: auto;
        }
    }
    .section-s-image-0-img-wrapper {
        height: 360px;
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .section-s-image-0-text-wrapper {
        box-shadow: 0px 22px 80px rgb(0 0 0 / 7%);
        padding: 55px 50px;
        position: absolute;
        background-color: $color-white;
        width: 45%;
        right: 11%;
        bottom: -30%;
        h2 {
            font-size: 48px;
            line-height: 55px;
            margin-bottom: 15px;
            color: $color-blue-darkest;
        }
        p,li,b,i {
            color: $color-grey-p;
            font-size: 18px;
            line-height: 25px;
            font-family: 'Sofia-Regular';
        }
        li {
            position: relative;
            left: 25px;
            margin-bottom: 0;
          }
          li::after {
            position: absolute;
            content: "";
            transform: rotate(45deg) scale(1);
            opacity: 1;
            left: -20px;
            top: 4px;
            width: 7px;
            height: 14px;
            border: solid #FF8000;
            border-width: 0 2px 2px 0;
            background-color: transparent;
            border-radius: 0;
          }
    }
}

@media (max-width: $breakpoint-tablet) {
    .section-s-image-0 + .section-s-image-0 {
        margin-top: 0px !important;
    }
    .section-s-image-0 {
        margin-bottom: 100px;
        padding-top: 50px;
        
        .hex-image-corner-wrapper {
            top: -9vw !important;
        }
        &.hex-right {
            .hex-image-corner-wrapper {
                .hex-image-corner-content {
                    background-position-y: top;
                }
            }
            .row {
                .col-lg-6 {
                    margin: 0% !important;
                }
            }
        }
        .section-s-image-0-text-wrapper {
            position: relative;
            width: 100%;
            left: 0 !important;
            top: 0 !important;
            padding: 30px 20px;
        }
    }
}

@media (max-width: $breakpoint-mobile) {
    .section-s-image-0 {
        padding-top: 0px !important;
        .hex-image-corner-wrapper {
            width: 87px;
            margin-left: -6vw !important;
            top: -7vw !important;
            .hex-image-corner-content {
                background-position-y: top;
            }
            
        }
        &.hex-right {
            .hex-image-corner-wrapper {
                margin-right: -6vw !important;
            }
        }
    }
}

@media (max-width: 560px) {
    .section-s-image-0 {
        .hex-image-corner-wrapper {
            margin-left: -3vw !important;
            .hex-image-corner-content {
                background-position-x: -6vw !important;
            }
            
        }
        &.hex-right {
            .hex-image-corner-wrapper {
                margin-right: -3vw !important;
            }
            .hex-image-corner-content {
                background-position-x: 6vw !important;
            }
        }
    }
}