.section-services-hex-list  {
    margin-bottom: 100px;
    h2 {
        font-size: 48px;
        line-height: 55px;
        margin-bottom: 45px;
        color:$color-blue-darkest;
    }
    ul {
        margin-bottom: 15px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 1fr;
        li {
            color: $color-grey-p;
            font-size: 18px;
            margin-bottom: 15px;
            padding-left: 45px;
            min-height: 37px;
            position: relative;
            font-family: 'Sofia-Regular';
            &::after {
                content: "";
                height: 83%;
                background-color: $color-blue-darkest;
                width: 1px;
                position: absolute;
                left: 11px;
                top: 29px;
            }
            &:last-of-type {
                &::after {
                    content: "";
                    height: 0;
                    width: 0;
                }
            }
            .hex-buller-wrapper {
                position: absolute;
                left: 0;
                .hex-bullet {
                    position: relative;
                    display: block;
                    height: 30px;
                    width: 23px;
                    background: $color-blue-darkest;
                    // position: relative;
                    // height: 23px;
                    // width: 30px;
                    // background: $color-blue-darkest;
                    -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
                    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
                    &::before {
                        top: 1px;
                        left: 1px;
                        height: calc(100% - 2px);
                        width: calc(100% - 2px);
                        background: white;
                        position: absolute;
                        content: '';
                        -webkit-clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
                        clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    
                    }
                }
                .hex-number {
                    position: absolute;
                    top: 7px;
                    left: 9px;
                    font-size: 14px;
                    color: $color-blue-darkest;
                    left: 0;
                    right: 0;
                    text-align: center;
                }
            }
        }
    }
    img {
        width: 100%;
        height: 650px;
        object-fit: cover;
        
    }

}

@media (max-width: $breakpoint-tablet) {
    .section-services-hex-list {
        margin-bottom: 100px;
        .see-more-wrapper {
            text-align: center;
        }
        .left-col-hex-list {
            position: initial !important;
        }
        h2 {
            position: absolute;
            top: 0;
            font-size: 36px;
            line-height: 45px;
        }
        ul {
            li {

            }
        }
        img {
            padding-top: 65px;
            margin-bottom: 25px;
            height: 250px !important;
        }
    }
}

@media (max-width: $breakpoint-mobile) {
    .section-services-hex-list {
        h2 {
            top:-25px;
        }
    }
}