
.section-s-image-5 {
    margin-bottom: 100px;
    h2 {
        font-size: 48px;
        line-height: 55px;
        color: #145571;
        text-align: left;
        margin-bottom: 115px;   
    }
 
    img {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: $breakpoint-tablet) {
    .section-s-image-5  {
        margin-bottom: 100px;
        h2 {
            width: 100%;
            font-size: 36px;
            line-height: 45px;
            margin-bottom: 30px;
        }
        

    }
}
