.section-resources-container {
    margin-bottom: 100px;
    .section-resources {
        .slick-dots {
            bottom: 0px;
        }
        h2 {
            color: $color-blue-darkest;
            font-size: 48px;
            line-height: 55px;
            margin-bottom: 45px;
        }
        h3 {
            color: $color-blue-darkest;
            font-size: 24px;
            line-height: 33px;
        }
        .slick-resources .slick-list {
            padding: 0 20% 0 0 !important;
        }
        .section-resources-wrapper {
            margin-bottom: 50px;
            .section-resources-top {
                height: 230px;
                margin-bottom: 15px;
                position: relative;
                a {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    height: 100%;
                    width: 100%;
                    display: inline-block;
                    &::after {
                        content: '';
                        width: 0%;
                        height: 5px;
                        left: 0%;
                        right: 0%;
                        margin: auto;
                        position: absolute;
                        background: transparent;
                        bottom: -5px;
                        transition: width .5s ease, background-color .5s ease;
                    }
                    &:hover {
                        &::after {
                            width: 100%;
                            background: linear-gradient(140.8deg, #FF8000 4.79%, #FDE74C 135.06%);
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: $breakpoint-tablet) {
    .section-resources-container {
        margin-bottom: 100px;
        .section-resources  {
            h2 {
                font-size: 36px;
                line-height: 45px;
            }
            h3 {
                text-align: center;
            }
        }
    }
  }