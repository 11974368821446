.site-footer {
    position: relative;
    background-color: $color-blue-dark;
    margin-top: 100px;
    .site-footer-wrapper {
      position: relative;
      background-color: $color-blue-dark;
      background-image: url('../../images/footer-polygon.svg');
      background-repeat: no-repeat;
      background-position: right 30px center;
      background-size: contain;
      z-index: 2;
      
        .site-footer-section-top {
          margin-bottom: 50px;
  
          .site-footer-section-top-container {
            display: flex;
            align-items: center;
  
            img {
              width: 100%;
              height: auto;
              display: block;
  
            }
          }
  
          .site-footer-social-wrapper {
            margin-left: auto;
  
            ul {
              display: flex;
  
              li {
                max-width: 24px;
                margin-left: 27px;
              }
            }
          }
        }
        
  
    }
  
  }
  
  
  .site-footer-section-category {
    margin-bottom: 12px;

    h3 {
      color: $color-orange;
      margin-bottom: 30px;
      font-size: 20px;
    }
  
    li {
      margin-bottom: 18px;

      a {
        color: $color-white;
        position: relative;
        &:hover {           
          &::after {
            
            content: '';
            width: 100%;
            height: 1px;
            background-color: #fff;
            display: block;
            position: absolute;
          }
        }
        &::after {
          
          content: '';
          position: absolute;
          bottom: -0.2rem;
          left: 0;
          width: 0%;
          height: 1px;
          background-color: #fff;
          -webkit-transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
          -o-transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
          transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
         }
      }
    }
  }
  
  .site-footer-section-pages-links {
    margin-bottom: 30px;
  
    ul {
      display: flex;
      width: 100%;
      justify-content: center;
  
      li {
        font-size: 20px;
        margin-right: 60px;
  
        a {
          color: $color-white;
          font-family: 'Sofia-Cond-SemiBold';
          position: relative;
          &:hover {           
            &::after {
              
              content: '';
              width: 100%;
              height: 2px;
              background-color: #fff;
              display: block;
              position: absolute;
            }
          }
          &::after {
            
            content: '';
            position: absolute;
            bottom: -0.2rem;
            left: 0;
            width: 0%;
            height: 2px;
            background-color: #fff;
            -webkit-transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
            -o-transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
            transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
           }
        }
      }
    }
  }
  
  .site-footer-pages-certification {
    margin-bottom: 33px;
  
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
  
      li {
        margin-right: 30px;
  
        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }
  
  .site-footer-section-bootom {
    font-weight: 300;
    padding-bottom: 20px;
  
    .footer-section-bootom-container {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
  
      div {
        // flex: 1 0 50%;
      }
  
      ul {
        display: flex;
  
        li:first-of-type {
          margin-right: 86px;
        }
  
        a {
          color: $color-white;
          text-decoration: underline;
          font-size: 14px;
        }
      }
  
      p {
        color: $color-white;
        font-size: 14px;
      }
    }
  }
  .site-footer::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: 1;
    top: -30px;
    transform-origin: left top;
    transform: skewY(-3deg);
    background-image: none;
  }
  
  @media (max-width: $breakpoint-tablet) {
  
    .site-footer-section-pages-links ul {
      justify-content: left;
      flex-wrap: wrap;
  
      li {
        width: 50%;
        margin-right: 0;
        padding: 0 15px;
        margin-bottom: 15px;
      }
  
      li:nth-child(odd) {
        padding-left: 0;
      }
    }
  
    .site-footer-logo-wrapper img {
      max-width: 162px;
    }
  }
  