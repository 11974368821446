// COLORS

$color-white : #fff;
$color-white-dark : #f4f4f4;
$color-grey : rgba(80, 80, 80, 1);
$color-grey-dark : #979797;
$color-grey-darkest : #5F6375;
$color-black : #333333;
$color-blue : #135570;
$color-blue-darkest: #145571;
$color-blue-dark : #093D53;
$color-blue-light : #29B6BD;
$color-aqua : #0FFF95;
$color-yellow : #FDE74C;
$color-yellow2 : #F6F930;
$color-yellow-dark: #BBBE64;
$color-yellow-semi-dark: #CFD11A;
$color-yellow-darkest: #E0CA3C;
$color-red-e : #ED1E3A;
$color-red-light : #DD2D4A;
$color-red-lightest : #E40066;
$color-red : #AA4465;
$color-green : #7DC95E;
$color-green2: #98CE00;
$color-blue-gree: #2BB6BD;
$color-orange: #FF8000;
$color-border-grey: #C4C4C4;
$color-grey-p: #505050;
$color-4-rack: #269FAB;
$color-2-rack: #1D798D;
$color-1-rack: #155672;


// BREAKPOINTS (used by other college)

$breakpoint-tablet: 992px;
$breakpoint-mobile: 768px;
