.section-connect {
    margin-bottom: 100px;
    h2 {
        
        font-size: 48px;
        line-height: 55px;
        color: #145571;
        width: 100%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 40px;
    }
    .row {
        &> td,p,span,i,th,b,em {
            color: #505050;
            font-size: 18px;
            line-height: 25px;
            display: block;
            width: 100%;
            // margin-bottom: 30px;
        }
    }
    .section-connect-wrapper {
        margin-bottom: 50px;
        & > div {
            margin-bottom: 5px;
        }
        a {
            display: flex;
            align-items: center;
            span {
                margin-left: 11px;
            }
        }
        .section-connect-img {
            margin-bottom: 15px;
            img {
                max-width: 200px;
                height: 80px;
                object-fit: contain;
                width: 100%;
            }
        }
        p, span {
            color: $color-grey-p;
            font-size: 18px;
            line-height: 25px;
        }
        & > div:last-of-type > a > img {
            margin-bottom: 4px;
        }

    }
}
@media (max-width: $breakpoint-tablet) {
    .img-bg-line {
        background-size: 60vw;
    }
    .section-connect {
        h2 {
            width: 100%;
            font-size: 36px;
            line-height: 45px;
            margin-bottom: 30px;
        }
        margin-bottom: 50px;
        .section-connect-wrapper {
            p, span {
                font-size: 14px;
            }
            .section-connect-img {
                img {
                    max-width: 162px;
                    height: 65px;
                }
            }
        }
    }
}