.section-table {

    margin-bottom: 100px;

    h2 {
        font-size: 48px;
        line-height: 55px;
        color: #145571;
        width: 70%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 40px;
    }
    table {
        
        background-color: white;
        border-collapse: separate;
        border-spacing: 2px;
        width: 100%;
        border: 0;
        margin: 0 auto;
        th {
            padding: 15px 17px;
            line-height: 23px;
            border: 0;
        }
        hr {
            position: relative;
            border: none;
            margin: 0 auto;
            text-align: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            left: 0;
            display: flex;
            justify-content: space-around;
            &::after {
                position: absolute;
                content: "";
                transform: rotate(45deg) scale(1);
                opacity: 1;
                width: 7px;
                height: 15px;
                top:calc(50% - 15px);
                border: solid #FF8000;
                border-width: 0 2px 2px 0;
                background-color: transparent;
                border-radius: 0;
            }
        }
        td {
            padding: 15px 17px;
            border: 0;
            position: relative;
        }
        td,p,span,i,th {
            &.table-bgc-blue {
                background-color: $color-2-rack;
                color: $color-white;
            }
            font-family: "Sofia-Regular" !important;
            // font-weight: 400 !important;
            line-height: 25px;
            text-align: left;

        }
        th {
            font-family: "Sofia-Bold" !important;
        }
        thead {
            color: $color-blue-darkest;
            font-size: 16px;
            background-color: #F3F6F8;
  
        }
        tbody {
            color: $color-grey-p;
            font-size: 16px;
            background-color: #F3F6F8;
            vertical-align: top;

        }
    }
}


@media (max-width: $breakpoint-tablet) {
    .section-table {
        margin-bottom: 100px;
        h2 {
            width: 100%;
            font-size: 36px;
            line-height: 45px;
            margin-bottom: 30px;
        }
        .container {
            width: 100%;
            padding: 15px;
        }
        .section-table-container {
            overflow-x: auto;
            overflow-y: hidden;
            padding-bottom: 50px;
            &::-webkit-scrollbar {
                height: 5px;
                background-color: #EBF0F1;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $color-blue-darkest;
            }
            table {
                tbody {
                    td {
                        min-width: 190px !important;
                    }
                }
            }
        }
    }
}