.section-news {
    margin-bottom: 100px;
    background-repeat: no-repeat;
    background-position: right -10% top 0%;
    background-size: auto;
    h1 {
        font-size: 48px;
        line-height: 55px;
        color: $color-blue-darkest;
        margin-bottom: 20px;
    }
    img {
        width: 100%;
        height: 342px;
        object-fit: cover;
        @media (max-width: $breakpoint-tablet) {
            margin-bottom: -5px;
        }
    }
    
    .btn-wrapper {
        margin: 0 auto;
        text-align: center;
        margin-top: 75px;
    }
    .section-news-container {
        display: none;
        margin-bottom: 30px;
        .row > div:first-of-type  {
            display: block;
            & > a {
                height: max-content;
            }
        }
        .section-news-content {
            background-color: $color-white;
            box-shadow:  0px 14px 80px rgba(0, 0, 0, 0.07);
            height: 100%;
            padding: 50px;
            max-height: 342px;
            
    
                ul {
                margin-bottom: 10px;
                li {
                    font-family: 'Sofia-Regular' !important;
                    font-weight: 400 !important;
                    line-height: 25px;
                    font-size: 18px;
                    color: #505050;
                    line-height: 25px;
                    margin-bottom: 10px;
                    padding-left: 30px;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        transform: rotate(45deg) scale(1);
                        opacity: 1;
                        left: 5px;
                        top: 5px;
                        width: 7px;
                        height: 14px;
                        border: solid #145571;
                        border-width: 0 2px 2px 0;
                        background-color: transparent;
                        border-radius: 0;
                    }
                }
            }
            a {
                margin-bottom: 20px;
                h2 {
                    color: $color-blue-darkest;
                    font-size: 24px;
                    line-height: 33px;
                }
            }
            span {
                font-family: 'Sofia-Cond-Light';
                font-size: 18px;
                line-height: 25px;
                color: $color-blue-darkest;
                margin-bottom: 20px;
                display: block;
            }
            p {
                font-size: 18px;
                color: $color-grey-p;
                line-height: 25px;
                margin-bottom: 15px;
            }
        }
    }
}
@media (max-width: $breakpoint-tablet) {
    .img-bg-line {
        background-size: 60vw;
    }
    .section-news {
        
        background-size: 40vw;
        background-position: right -10% top 0%;
        h1 {
            font-size: 48px;
            line-height: 55px;
        }
        .section-news-container {
            .section-news-content {
                padding: 20px 20px 30px;
                h2 {
                    font-size: 24px;
                    line-height: 33px;
                }
                p {
                    font-size: 18px;
                    line-height: 25px;
                }
            }
        }
    }
}