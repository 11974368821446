// section mobile icon menu
.burger {
    position: relative;
    display: none;
    cursor: pointer;
    width: 33px;
    height: 1rem;
    border: none;
    outline: none;
    opacity: 0;
    visibility: hidden;
    background: none;
    transform: rotate(0deg);
    transition: 0.35s ease-in-out;

    &-line {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        border: none;
        outline: none;
        opacity: 1;
        border-radius: 3px;
        background: $color-blue-darkest;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 0.5rem;
        }

        &:nth-child(4) {
            top: 1rem;
        }
    }

    &.active {
        .burger-line {
            &:nth-child(1),
            &:nth-child(4) {
                top: 1.25rem;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }
}

// Section: Responsive Media Query

@media (max-width: 1200px) {
    .search-icon {
        display: none;
    }
    .burger {
        display: block;
        opacity: 1;
        visibility: visible;
        margin-left: auto;
    }
    .site-header-navigation-logo-wrapper {
        margin: 0 !important;
    }
    .site-header-mobile-wrapper {
        display: none !important;
    }
    .site-header-mobile-wrapper.active {
        display: flex !important;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 90px;
        z-index: 99999;
        background-color: $color-white;
        padding-bottom: 50px;
        box-shadow:  0px 100px 80px rgba(0, 0, 0, 0.07);

        .navigation-first-list {

            img {
                position: absolute;
                right: 0;
            }
        }
        .navigation-first-list.active {
            img {
                -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
            }
        }
        .site-header-navigation-list {
            order: 2;
            padding: 0 30px 50px 30px;
            width: 100%;
           & > ul {
                display: flex;
                flex-direction: column;
                & > li:nth-last-of-type(-n+2) img{
                    display: none;
                }
                & > li {
                    margin: 0 0 40px 0;
                
                    .site-header-navigation-dropdown {
                        min-width: inherit;
                        position: relative;
                        left: auto;
                        right: auto;
                        transform: inherit;
                        display: none;
                        ul {
                            padding: 20px 0 0 0;
                            box-shadow: none;
                            display: flex;
                            flex-direction: column;
                            li {
                                margin: 0 0 20px 0;
                                a {
                                    font-size: 20px;
                                    color: $color-blue-gree;
                                    span {
                                        display: block;
                                        font-size: 14px;
                                        font-family: "Sofia-Regular";
                                        margin-top: 5px;
                                        color: $color-grey;
                                    }
                                }
                            }
                        }
                    }
                    .site-header-navigation-dropdown.active {
                        display: block;
                    }
                }
            }
        }
    }
    .site-header-navigation-search {
        width: 100%;
        ul {
            width: 100%;
            padding: 15px 15px 40px 15px;
            li {
                margin: 0 !important    ;
            }
            .site-header-nav-search-list {
                width: 100%;
                display: flex;
                height: 50px;
                .search-nav-form {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    input {
                        border: 1px solid $color-blue-gree;
                        width: 100%;
                        padding-left: 15px;
                        outline: none;
                        font-size: 24px;
                        color: $color-blue-dark;
                        padding-right: 40px;
                        &::placeholder { 
                            color: $color-blue-darkest;
                            font-weight: bold;
                            padding-left: 5px;
                            opacity: 1;
                          }
                          &:-ms-input-placeholder {
                            color: $color-blue-darkest;
                            font-weight: bold;
                            padding-left: 5px;
                            opacity: 1;
                          }
                    }
                }
                
                img {
                    position: absolute;
                    right: 30px;
                    top: 27px;
                }
            }
            
            .site-header-nav-lang-list {
                position: absolute;
                bottom: 50px;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
                margin-bottom: 50px;
                a {
                    margin: 0 10px;
                }
                .lang-list-active {
                    background-image: url('../../images/lang-hex.svg');
                    background-size: contain;
                    font-weight: bold;
                    padding: 18px;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                a {
                    display: inline-block;
                }
            }
        }
    }
}