.section-hex-person {
  margin-bottom: 100px;
  position: relative;
  .slick-dots {
    bottom: 0;
    justify-content: left;
  }
    .hex-person-top {
      margin-bottom: 130px;
      display: flex;
      align-items: center;
      h2 {
        font-size: 48px;
        color: $color-blue-darkest;
      }
      a {
        margin-left: auto;
        font-size: 20px;
        position: relative;
        color: $color-blue-darkest;
        font-weight: bold;
        &:hover {
          &::after {
            transform: translateX(-140%);
          }
        }
        &::after {
          content: "";
          width: 40%;
          background-color: #FF8000;
          height: 3px;
          position: absolute;
          right: 0;
          bottom: -4px;
          transition: transform 0.3s ease;
          transform: translateX(0px);
        }
      }
    }
  .hex-person-left-wrapper {
    // .slick-list {
    //   overflow: visible;
    // }
    padding-top: 25px;
    .hex-person-left-content {
      animation-name: fadeInLeftSide;
      animation-duration: 1s;
    }
    .hex-person-left-white-container{
      position: absolute;
      bottom: -17%;
      right: -10%;
      z-index: 9;
      display: flex;
      align-items: center;
      .hex-person-left-logo {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
    .hex-person-left-orange-img{
      position: absolute;
      left: -10%;
      top: 32%;
      z-index: 9;
    }
    .hex-person-left-blue-img{
      position: absolute;
      z-index: 9;
      right: 21%;
      top: 7%;
    }
    .hexagon {
      position: relative;
      width: 100%;
      height: 580px;
      .shape {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            object-fit: cover;
        }
      }
    }
  }
  .hex-person-right-wrapper {

    padding-bottom: 50px;

    .hex-person-right-content {
      h3 {
        font-size: 24px;
        line-height: 33px;
        color: $color-blue-darkest;
        margin-bottom: 30px;
      }
      p {
        font-size: 18px;
        color: $color-grey-p;
        margin-bottom: 30px;
        font-family: 'Sofia-Cond-Light';
      }
      h4 {
        font-size: 24px;
        margin-bottom: 10px;
        color: $color-blue-darkest;
      }
      span {
        font-size: 18px;
        font-family: 'Sofia-Cond-Light';
        color: $color-blue-darkest;
      }
    }
  }
}
.slick-active .hex-person-left-logo ,
.slick-active .hex-person-left-orange-img {

  animation-name: fadeInLeftSide;
  animation-duration: 1s;
  opacity: 1;
}
.slick-active .hex-person-left-blue-img {
  animation-name: fadeInRightSide;
  animation-duration: 1s;
  opacity: 1;
}
.slick-active.hex-person-right-content > div {
  animation-name: fadeInRightSide;
  animation-duration: 1s;
  opacity: 1;
}

@media (max-width: $breakpoint-tablet) {
  .section-hex-person {
    padding-top: 40px;
    overflow: hidden;
    .slick-dots {
      justify-content: center;
    }
    .hex-person-right-wrapper {
      margin-top: 60px;
    }
    .hex-person-left-wrapper {
        .hexagon {
          height: 580px;
          width: 445px;
          margin: 0 auto;
        }
      }
    .hex-person-top {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      padding-left: 15px;
      a {
        right: 15px;
      }

    }
  }
}

@media (max-width:540px) {
  .section-hex-person {
    .hex-person-left-wrapper {
      .hex-person-left-blue-img {
        width: 32px;
        height: 42px;
      }
      .hex-person-left-orange-img {
        width: 54px;
        height: 70px;
        left: 11%;
      }
      .hex-person-left-white-container {
        transform: scale(0.5);
        bottom: -40%;
      }
      .hexagon {
        height: 300px;
        width: 100%;
        .shape {
          right: 0;
          margin: 0 auto;
          position: relative;
          width: 215px;
          height: 280px;
          img {
            position: relative;
          }
        }
      }
    }
  }
}
