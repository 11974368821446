.checkbox-label {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 12px;
    color: $color-white;
    font-family: 'Sofia-Cond-SemiLight';
    &::before {
        position: absolute;
        content: "";
        left: 10px;
        top: 10px;
        width: 0;
        height: 0;
        border-radius: 3px;
        border: 1px solid #fff;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }
    input {

        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        &:checked ~ .checkbox-custom {
            background-color: white;
            -webkit-transform: rotate(0deg) scale(1);
            -ms-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
            opacity: 1;
            border: 1px solid white;
        }
        &:checked ~ .checkbox-custom::after {
            -webkit-transform: rotate(
        45deg) scale(1);
            -ms-transform: rotate(45deg) scale(1);
            transform: rotate(
        45deg) scale(1);
            opacity: 1;
            left: 4px;
            top: 2px;
            width: 5px;
            height: 9px;
            border: solid #145571;
            border-width: 0 2px 2px 0;
            background-color: transparent;
            border-radius: 0;
        }
    }
    .checkbox-title {
        margin-left: 30px;
        width: 100%;
    }
    &.error {    
        .checkbox-custom {
            border: 1px solid $color-red-e;
        }
    }
    .checkbox-custom {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: transparent;
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        border: 1px solid white;
        &::after {
            position: absolute;
            content: "";
            left: 12px;
            top: 12px;
            height: 0;
            width: 0;
            border-radius: 3px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(0deg) scale(0);
            -ms-transform: rotate(0deg) scale(0);
            transform: rotate(0deg) scale(0);
            opacity: 1;
            -webkit-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
        }
    }
}