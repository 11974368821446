.section-services-top {
    text-align: left;
    padding-bottom: 150px;
    &.img-bg-hex {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left -80px top 0%;
        // max-height: 500px;
    }
    @media (max-width:1100px) {
        &.img-bg-hex {
            background-size: 0 !important;
        }
    }
    h1 {
        font-size: 48px;
        line-height: 55px;
        color: $color-blue-darkest;
        margin-bottom: 20px;
    }
    p,i,b,em,strong {
        color: $color-grey-p;
        font-size: 18px;
        line-height: 25px;
        font-weight: revert;
    }
    ul {
        margin: 10px 0;
        li {
            font-family: 'Sofia-Regular' !important;
            font-weight: 400 !important;
            line-height: 25px;
            font-size: 18px;
            color: $color-grey-p;
            line-height: 25px;
            margin-bottom: 10px;
            padding-left: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                transform: rotate(45deg) scale(1);
                opacity: 1;
                left: 5px;
                top: 5px;
                width: 7px;
                height: 14px;
                border: solid  $color-orange;
                border-width: 0 2px 2px 0;
                background-color: transparent;
                border-radius: 0;
            }
        }
    }
}
@media (min-width: $breakpoint-tablet) {
    .section-services-top  {
        span {
            // display: none;
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .section-services-top {
        &.img-bg-hex {
            background-size: contain;
            background-position: left -20% top 0%;
        }
        padding-bottom: 100px;
        h1 {
            size: 36px;
            line-height: 45px;
        }
        p,i,b,em,strong {
            // max-height: 175px;
            // overflow: hidden;
        }
        span {
            position: relative;
            margin-top: 20px;
        }
    }
}
@media (max-width: $breakpoint-mobile) {
    .section-services-top {
        &.img-bg-hex {
            background-size: 40%;
            background-position: left -45% top 0%;
        }
    }
}
@media (max-width: 450px) {
    .section-services-top {
        &.img-bg-hex {
            background-size: 230px;
        }
    }
}
