
.section-s-text-4 {
    
    ul {
        margin-bottom: 10px;
        li {
            font-family: 'Sofia-Regular' !important;
            font-weight: 400 !important;
            line-height: 25px;
            font-size: 18px;
            color: #505050;
            line-height: 25px;
            margin-bottom: 10px;
            padding-left: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                transform: rotate(45deg) scale(1);
                opacity: 1;
                left: 5px;
                top: 5px;
                width: 7px;
                height: 14px;
                border: solid #145571;
                border-width: 0 2px 2px 0;
                background-color: transparent;
                border-radius: 0;
            }
        }
    }
    .img-bg-hex {
        background-repeat: no-repeat;
        background-size: contain;    
        background-position: right -12% top 0%;
    }
    @media (max-width:1250px) {
        .img-bg-hex {
            background-size: 0 !important;
        }
    }
    .see-more {
        display: none !important;
    }
    .img-bg-hex ,
    .img-bg-blue-hex  {
        .section-s-text-4-wrapper {
            padding-bottom: 150px;
        }
    }
    
    .section-s-text-4-wrapper {
        padding-bottom: 100px;
        h1 {
            font-size: 48px;
            line-height: 55px;
            color: $color-blue-darkest;
            width: 70%;
            margin-bottom: 30px;
        }
        p {
            font-size: 18px;
            line-height: 25px;
            color: $color-grey-p;
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .section-s-text-4 {
        .d-lg-none {
            display: none !important;
        }
        .see-more {
            display: inline-block !important;
            margin-top: 20px;
        }
        .img-bg-hex {
            background-size: contain;
            background-position: right -20% top 0%;
        }
        .section-s-text-4-wrapper  {
            padding-bottom: 100px;
            h1 {
                font-size: 36px;
                line-height: 45px;
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}
@media (max-width: $breakpoint-mobile) {
    .section-s-text-4 {
        .img-bg-hex {
            background-size: 40%;
            background-position: right -45% top 0%;
        }
    }
}
@media (max-width: 450px) {
    .section-s-text-4 {
        .img-bg-hex {
            background-size: 230px;
        }
    }
}