.section-partners {
    margin-bottom: 100px;
    h1 {
        color: $color-blue-darkest;
        font-size: 48px;
        line-height: 55px;
        margin-bottom: 45px;
    }
    .tab{
        display: none;
    }
    .tab-active{
        display: block;
    }
    .section-partners-tab-menu {
        margin-bottom: 45px;
        ul {    
            display: flex;
            justify-content: space-between;
            li {
                a {
                    font-size: 20px;
                    line-height: 25px;
                    color: $color-blue-darkest;
                    height: 41px;
                    border: 1px solid $color-blue-darkest;
                    padding: 0 32px;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    background-color: $color-white;
                    &.active-a {
                        border: none;
                        color: $color-white;
                        background: linear-gradient(130.91deg, #145571 8.77%, #2BB6BD 125.18%);
                    }
                }
            }
        }
        .tab-content-partners {
            img {
                background-repeat: no-repeat;
            }
        }
    }
    .tab-content-partners {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 50px;
        img {
            margin-bottom: 10px;
            max-width: 100%;
            height: 80px;
            object-fit: contain;
            width: 100%;
        }
        span {
            font-size: 18px;
            color: $color-grey-p;
            display: block;
            text-align: center;
        }
    }
}
@media (max-width: $breakpoint-tablet) {
    .section-partners {
        .tab-content-partners {
            img {
                max-width: 162px;
                height: 65px;
            }
        }
        .section-partners-tab-menu {
            ul{
                li{
                    a {
                        font-size: 18px;
                        padding: 0 12px;
                    }
                }
            }
        }
    }
  }

@media (max-width: $breakpoint-mobile) {
    .section-partners {
        margin-bottom: 50px;
        h1 {
            display: none;
        }
        .section-partners-tab-menu {
            overflow: auto;
            padding-bottom: 10px;
            &::-webkit-scrollbar {
                height: 5px;
                background-color: #EBF0F1;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $color-blue-darkest;
            }
            ul {
                width: max-content;
                li {
                    &:first-of-type a {
                        margin-left: 0px;
                    }
                    a {                     
                        font-size: 18px;
                        padding: 0 32px;
                        margin-left: 10px;
                        width: max-content;
                      
                    }
                }
            }
        }
    }
  }