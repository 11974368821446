
.section-text-column {
  padding: 50px 0;
  margin-bottom: 100px;
  
  h3 {
    color: $color-white;
    margin-bottom: 10px;      
    font-size: 24px;
    line-height: 33px;
  }
  &.section-text-blue {
    background: linear-gradient(130.91deg, #145571 8.77%, #2BB6BD 125.18%);
    .section-text-column-wrapper {
      border-right: 0.5px solid $color-border-grey;
    }
    .see-more {
      color: $color-white;
    }
  }
  &.section-text-white {
    h2,h3,p,i,b,em,strong {
      color: $color-blue-darkest !important;
    }
  }
  &.section-text-orange {
    background: linear-gradient(140.8deg, #FF8000 4.79%, #FDE74C 135.06%);
    .section-text-column-wrapper {
      border-right: 0.5px solid $color-white;
    }


    .see-more {
      color: $color-white;
      &::after {
        background-color: $color-white;
      }
    }
  }
  h2 {
    font-size: 36px;
    margin-bottom: 35px;
    text-align: center;
    color: $color-white;
    padding-right: 16px;
  }
  
  @media (max-width: $breakpoint-mobile) {
    h2 {
      padding-right: 0;
    }
  }

  .row>div:last-of-type>.section-text-column-wrapper {
    border-right: none;
  }

  .section-text-column-wrapper {
    padding: 16px 16px 16px 0;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-content: flex-start;
    .see-more {
      margin-top: 10px;
    }
    p,i,b,em,strong {
      color: $color-white;
      font-family: 'Sofia-Cond-Light';
      font-weight: bold;
      width: 100%;
    }
    ul {
      margin: 10px 0;
      li {
          font-family: 'Sofia-Regular' !important;
          font-weight: 400 !important;
          line-height: 25px;
          font-size: 18px;
          color: $color-white;
          line-height: 25px;
          margin-bottom: 10px;
          padding-left: 30px;
          position: relative;
          &::before {
              position: absolute;
              content: "";
              transform: rotate(45deg) scale(1);
              opacity: 1;
              left: 5px;
              top: 5px;
              width: 7px;
              height: 14px;
              border: solid  $color-white;
              border-width: 0 2px 2px 0;
              background-color: transparent;
              border-radius: 0;
          }
      }
  }
  }
}

@media (max-width: $breakpoint-tablet) {
  .section-text-column {
    padding: 30px 0 0;
    margin-bottom: 100px;

    h2 {
      margin-bottom: 25px;
    }

    .row>div:last-of-type>.section-text-column-wrapper {
      border-bottom: none;
    }
    &.section-text-blue {
      .section-text-column-wrapper {
        border-bottom: 0.5px solid $color-border-grey;
      }
    }
    &.section-text-orange {
      .section-text-column-wrapper {
        border-bottom: 0.5px solid $color-white;
      }
    }
    .section-text-column-wrapper {
      // padding: 0 0 24px 0;
      margin-bottom: 24px;
      border-right: none !important;
    }
  }
}