.section-article {
    margin-bottom: 100px;
    h1 {
        font-size: 48px;
        line-height: 55px;
        color: $color-blue-darkest;
        margin-bottom: 40px;
    }
    img {
        width: 100%;
        background-size: cover;
    }
    .section-article-container {
        .section-article-content {
            padding-left: 30px;
            p {
                font-size: 18px;
                color: $color-grey-p;
                line-height: 25px;
                margin-bottom: 20px;
            }
            ul {
                margin-bottom: 10px;
                li {
                font-size: 18px;
                color: $color-grey-p;
                line-height: 25px;
                margin-bottom: 10px;
                padding-left: 30px;
                position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        transform: rotate(45deg) scale(1);
                        opacity: 1;
                        left: 5px;
                        top: 5px;
                        width: 7px;
                        height: 14px;
                        border: solid $color-blue-darkest;
                        border-width: 0 2px 2px 0;
                        background-color: transparent;
                        border-radius: 0;
                    }
                }
            }
            .section-article-social {
                display: flex;
                align-items: center;
                    span {
                        font-size: 20px;
                        color: $color-blue-darkest;
                        font-weight: bold;
                    }
                    a {
                        margin-left: 10px;
                        img {
                            width: auto;
                        }
                    }
            }
        }
    }
}
@media (max-width: $breakpoint-tablet) {
    .img-bg-line {
        background-size: 60vw;
    }
    .section-article {
        h1 {
            font-size: 36px;
            line-height: 45px;
            position: absolute;
            top: 0;
        }
        .section-article-container {
            position: relative;
            padding-top: 100px;
            .section-article-content {
                margin-top: 20px;
                padding: 0;
                p {
                    font-size: 18px;
                    line-height: 25px;
                }
            }
        }
    }
}
@media (min-width:768px) {
    .section-article .container {
      width: calc(((100% - 750px) / 2) + 750px) !important;
      margin-left: 0;
    }
  }
  
  @media (min-width:992px) {
    .section-article .container {
      width: calc(((100% - 970px) / 2) + 970px) !important;
      margin-left: 0;
      padding: 0;
    }
  }
  
  @media (min-width:1200px) {
    .section-article .container {
      width: calc(((100% - 1170px) / 2) + 1170px) !important;
      margin-left: 0;
      padding: 0;
    }
  }