.section-service-column-container {
  margin-bottom: 100px;
  padding-top: 100px;
  // background-image: url('../../images/service-bg-full.svg');
  // background-position: 0 0;
  // background-repeat: no-repeat;
  // background-size: contain;

  .row {
    margin: 0 -5px;

    .col {
      margin-bottom: 10px;
    }
  }

  .no-shadow {
    box-shadow: none !important;
    &:hover {
      transform: none !important;
    }
  }

  .service-column-wrapper {
    margin: 0 5px;
    // height: 100%;
    overflow: hidden;
    box-shadow: 0px -12px 80px rgba(0, 0, 0, 0.07);
    -webkit-backface-visibility: hidden; 
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
    transition: transform .2s;
    &:hover {
      transform: scale(1.02);
    }
    .service-column {
      height: 100%;
      width: 100%;
      padding: 50px 35px 75px;
      max-height: 350px;
      display: inline-block;
      img {
        margin-bottom: 20px;
        max-height: 100px;
        background-size: cover;
      }

      h3 {
        font-size: 48px;
        line-height: 55px;
        margin-bottom: 25px;
      }

      h4 {
        font-size: 24px;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        // font-weight: bold;
        // font-family: "Sofia-Thin";
      }

      &>a {
        font-size: 20px;
        position: relative;
        margin-top: 25px;
        &:hover {
          &::after {
            animation-name: fadeInLeftSide;
            animation-duration: 1s;
          }
        }
        &::after {
          content: "";
          width: 40%;
          background-color: $color-orange;
          height: 3px;
          position: absolute;
          right: 0;
          bottom: -4px;
        }
      }
    }

    .service-column-transparent {
      padding: 50px 20px 50px 0;
      box-shadow: none !important;

      p {
        font-size: 18px;
        margin-bottom: 25px;
      }

      &>* {
        color: $color-blue-darkest;
      }
    }

    .service-column-white {
      background: $color-white;

      &>* {
        color: $color-blue-darkest;
      }
    }

    .service-column-blue {
      background: linear-gradient(130.91deg, #145571 8.77%, #2BB6BD 125.18%);

      &>* {
        color: $color-white;
      }
    }

    .service-column-orange {
      background: linear-gradient(140.8deg, #FF8000 4.79%, #FDE74C 135.06%);

      &>* {
        color: $color-white;
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .section-service-column-container {
    .service-column-wrapper  {
      .service-column {
        min-height: 350px;
      }
    }
  }
}
@media (max-width: $breakpoint-mobile) {

  .section-service-column-container {
    background-size: 200px;
    
    .container {
      margin: 0 auto;
      width: 100%;
    }

    .service-column-wrapper {
      .service-column {
        padding: 15px;
        text-align: center;
        width: 100%;

        p {
          display: none;
        }
      }

      .service-column-transparent {
        text-align: left !important;
        padding: 15px 0;

        h3 {
          font-size: 34px;
          line-height: 45px;
        }
      }
    }


  }

}