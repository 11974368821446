.btn-wrapper {
    margin: 0 auto;
    text-align: center;
}
.section-404 {
    margin-bottom: 100px;
    h1 {
        text-align: center;
        font-size: 48px;
        line-height: 55px;
        color: $color-blue-darkest;
        margin-bottom: 60px;
    }
    .img-wrapper {
        text-align: center;
        margin-bottom: 60px;
        svg {
            width: 70%;
            margin-bottom: 60px;
            overflow: visible;
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
        }
    }
    p {
        text-align: center;
        font-size: 18px;
        color: $color-grey-p;
        line-height: 25px;
        margin-bottom: 60px;
    }
}
@media (max-width: $breakpoint-tablet) {
    .section-404 {
        margin-bottom: 100px;
        h1 {
            font-size: 36px;
            line-height: 55px;
            margin-bottom: 30px;
        }
        p {
            margin-bottom: 30px;
            font-size: 18px;
            line-height: 25px;
        }
        .img-wrapper {
            svg {
                width: 100%;
                margin-bottom: 0px;
            }
        }
    }
}

