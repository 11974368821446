.btn-orange {
    background: linear-gradient(140.8deg, #FF8000 4.79%, #FDE74C 135.06%);
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    color: white;
    border: none;
    padding: 12.5px 100px;
    cursor: pointer;
    margin-bottom: 50px;
    transition: box-shadow 0.3s ease-in-out;
}
.btn-orange:hover {
    background: linear-gradient(-0.8deg, #FF8000 -42.79%, #FDE74C 135.06%);
    // background-size: 130%;
    // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}
.btn-orange:active {
    outline: 2px solid #FF8000;
    outline-offset: -2px;
}