body {
  font-family: 'Sofia-Cond-Regular';
}
.cc-window {
  font-family: 'Sofia-Cond-Regular';
}
h1,h2,h3,h4,h5,h6 {
  font-family: 'Sofia-Cond-SemiBold';
}
p {
  font-family: 'Sofia-Regular' !important;
  font-weight: 400 !important;
  line-height: 25px;
}
.img-bg-line {
  background-image: url(../../images/service-bg-full.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.see-more {
  display: inline-block;
  font-size: 20px;
  color: $color-blue-darkest;
  position: relative;
  &:hover {
    &::after {
    transform: translateX(-140%);
    }
  }
  &::after {
      content: "";
      width: 40%;
      background-color: #FF8000;
      height: 3px;
      position: absolute;
      right: 0;
      bottom: -4px;
      transition: transform 0.3s ease;
      transform: translateX(0px);
  }
}

.img-bg-blue-hex {
    background-image: url('../../images/hex-blue.svg');
  }
  
  .img-bg-orange-hex {
    background-image: url('../../images/hex-orange.svg');
  }

  .services-bg-img-top .img-bg-orange-hex {
    background-repeat: no-repeat;
    background-size: auto;    
    background-position: left -12% top 4%;
}

@media (max-width: $breakpoint-tablet) {
    .services-bg-img-top .img-bg-orange-hex {
        background-size: 30%;
        background-position: left -20% top 0%;
    }
}
@media (max-width: $breakpoint-mobile) {
    .img-bg-line {
        background-size: 50%;
        background-position: left 0% top 5%;
    }
    .services-bg-img-top .img-bg-orange-hex {
        background-size: 50%;
        background-position: left -45% top 0%;
    }
}


/* List page ( DEMO PAGE ) */

.list-url {
  padding:20px 50px ;
  h1 {
      color: $color-orange;
      font-size: 46px;
      margin-bottom: 20px;
  }
  li {
      margin-left: 20px;
      list-style-type: none;
      margin-bottom: 3px;
  }
  a {
      color: $color-blue-darkest;
      font-size: 22px;
  }
}
.section-contact-map {
  bottom: -100px;
  position: relative;
}

.bg-line-left,
.bg-line-right {
  position: absolute;
  width: 25vw;
  z-index: -1;
  img {
    width: 100%;
  }
}
.bg-line-right {
  right: 0;
  // bottom: -520px;
  bottom: 100vh;
  transform: rotate(180deg);
}
.bg-line-left {
  left: 0;
  top: 0;
}

@media (max-width: $breakpoint-mobile) {
  .bg-line-left,
  .bg-line-right {
    width: 55vw;
  }
  .bg-line-right {
    bottom: 100vh;
  }
  .bg-line-left {
    top: 50vh;
  }
}