.section-home-hex-2 {
    margin-bottom: 100px;
    h2 {
      font-size: 48px;
      text-align: center;
      color: $color-blue-darkest;
    }
    .section-home-hex-2-container {
      margin-top: 20%;
      .home-hex-2-column { 
          margin-top: -8.5%;
          
          .hex-column-blue,
          .hex-column-orange {
            .hex-content {
              h3 {
                color: $color-white;
              }
              p,i,b,em,strong {
                color: $color-white;
              }
              li {
                color: $color-white;
                &::before {
                  position: absolute;
                  content: "";
                  transform: rotate(45deg) scale(1);
                  opacity: 1;
                  left: -20px;
                  top: 7px;
                  width: 7px;
                  height: 14px;
                  border: solid $color-white;
                  border-width: 0 2px 2px 0;
                  background-color: transparent;
                  border-radius: 0;
                }
              }
            }
          }
        img {
          width: 100%;
          height: 100%;
          transform: scale(1.3);
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
        }
        .hex-content {
          position: absolute;
          top: 20%;
          left: 0;
          padding: 0 10% ;
            h3 {
              font-size: 36px;
              margin-bottom: 25px;
              color: $color-blue-darkest;
            }
            p,i,b,em,strong {
              position: relative;
              color: $color-grey-p;
              font-size: 18px;
              margin-bottom: 20px;
              font-family: 'Sofia-Regular' !important;
              font-weight: 400 !important;
              line-height: 25px;
            }
            li {
              position: relative;
              color: $color-grey-p;
              font-size: 18px;
              margin-bottom: 20px;
              font-family: 'Sofia-Regular' !important;
              font-weight: 400 !important;
              line-height: 25px;
              margin-left: 25px;
              &::before {
                position: absolute;
                content: "";
                transform: rotate(45deg) scale(1);
                opacity: 1;
                left: -20px;
                top: 7px;
                width: 7px;
                height: 14px;
                border: solid $color-orange;
                border-width: 0 2px 2px 0;
                background-color: transparent;
                border-radius: 0;
                  }
              }
          }
        }
      }
      .slick-dots {
        bottom: 0;
      }
    }
  
  @media (max-width: $breakpoint-tablet) {
    .section-home-hex-2 {
      margin-bottom: 100px;
      h2 {
        font-size: 36px;
      }
      .section-home-hex-2-container {
        margin-top: 10%;
        .home-hex-2-column {
          flex: inherit !important;
          margin-top: 0 !important;
          padding-top: 4%;
          padding-bottom: 4%;
        }
      }
    }
    
  }
  @media (max-width: 460px) {
    .section-home-hex-2 {
      .hex-content {
        padding: 0 5% 0 10% !important;
        h3 {
          margin-bottom: 5px !important;
          font-size: 24px !important;
        }
        li {
          &::before {
            left: -17px !important;
          }
          margin-bottom: 10px !important;
          font-size: 16px !important;
        }
      }
    }
    
  }
  