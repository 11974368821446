
.section-gallery {
  margin-bottom: 100px;
  .section-gallery-container {
    h2 {
      font-size: 48px;
      line-height: 55px;
      color: $color-blue-darkest;
      margin-bottom: 40px;
    }
  }
}
.ug-gallery-wrapper.ug-lightbox {
  z-index: 99999999999999999;
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon.ug-icon-zoom {
  background-image: url('../../images/gallery-btn.svg');
  width: 40px;
  height: 52px;
}
.ug-thumb-wrapper.ug-tile .ug-tile-icon:hover {
  background-position:center;
  
}

.ug-thumb-wrapper > img {

  -o-transition: all 300ms ease-in-out !important;
  -ms-transition: all 300ms ease-in-out !important;
  -webkit-transition: all 300ms ease-in-out !important;
  -moz-transition: all 300ms ease-in-out !important;
  transition: all 300ms ease-in-out !important;  
}
.ug-thumb-wrapper:hover > img {
  transform: scale(1.1);
}
.ug-lightbox .ug-lightbox-overlay {
  opacity: 0.8 !important;
}
.ug-lightbox .ug-lightbox-top-panel-overlay {
  background-color: $color-blue-dark;
  opacity: 1;
}
.ug-textpanel-textwrapper {
  display: none;
}


@media (max-width: $breakpoint-tablet) {
  .section-gallery {
    margin-bottom: 100px;
  }
}
