.section-contact {
    padding-bottom: 150px;
    .img-bg-orange-hex {
        background-repeat: no-repeat;
        background-size: auto;
        background-position: right -10% top 10%;
    }
    .help-block {
        font-size: 12px;
        line-height: 12px;
        color: $color-red-e;
        margin-left: 5px;
        margin-top: 5px;
        position: absolute;
      }
    h1 {
        color: $color-blue-darkest;
        font-size: 48px;
        line-height: 55px;
        margin-bottom: 45px;
    }
    .footer-form-input-container {
        border: 1px solid $color-blue-gree;
        margin-bottom: 30px;
        input {
          height: 48px;
          color: $color-blue-darkest;
          padding-left: 15px;
          top: 15px;
          font-size: 18px;
        }
        textarea {
            color: $color-blue-darkest;
            padding-left: 15px;
            top: 15px;
            font-size: 18px;
        }
        .bar:before,
        .bar::after {
          bottom: -1px;
          background: $color-blue-gree;
        }
      }
      .g-recaptcha {
          margin-bottom: 30px;
      }
      .textarea-wrapper {
        position: relative;
        textarea {
            resize: vertical;
        }
        .pull-tab {
            height: 0px;
            width: 0px;
            border-top: 10px solid #FF8000;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            -webkit-transform: rotate(-45deg);
            position: absolute;
            bottom: -1px;
            right: -6px;
            pointer-events: none;
            z-index: 2;
        }
      }
      .section-contact-info:nth-child(2){
        -webkit-animation-delay: 0.5s;
        -moz-animation-delay: 0.5s;
        animation-delay: 0.5s;
        color: red;
    }
    .section-contact-info:nth-child(3){
        -webkit-animation-delay: 1s;
        -moz-animation-delay: 1s;
        animation-delay: 1s;
    }
    .section-contact-info {
        margin-bottom: 40px;
        h4 {
            margin-bottom: 10px;
            font-size: 24px;
            line-height: 33px;
            color: $color-blue-darkest;
        }
        p {
            font-size: 18px;
            line-height: 25px;
            color: $color-grey-p;
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .img-bg-line-2 {
 
        background-size: 60vw;
    }
    .section-contact {
        margin-bottom: 100px;
        .img-bg-orange-hex {
            background-size: 40vw;
            background-position: right -10% top 0%;
        }
        h1 {
            font-size: 36px;
            line-height: 45px;
        }
        .btn-wrapper {
            margin: 0 auto;
            text-align: center;
        }
    }
  }
