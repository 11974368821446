.accordion-wrapper {

    .accordion-list {
        width: 100%;
        & > li {
            border-bottom: 4px solid $color-orange;
            box-shadow:  0px 22px 80px rgba(0, 0, 0, 0.07);
            padding: 0 30px;
            margin-bottom: 30px;
            background-color: $color-white;
            .accordion-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                height: 100px;
              }
              .accordion-btn-wrapper {
                  text-align: center;
                  margin-top: 30px;
              }
              .accordion-content {
                max-height: 0;
                overflow: hidden;
                -webkit-transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                span,li,p {
                    color: $color-grey-p;
                    font-size: 18px;
                    line-height: 25px;
                    font-family: "Sofia-Regular" !important;
                }
                span {
                    margin-bottom: 30px;
                    display: block;
                }
                h4 {
                    font-size: 24px;
                    line-height: 33px;
                    margin-bottom: 15px;
                    color: $color-blue-darkest;
                }
                p {
                    margin-bottom: 30px;
                }
                .accordion-content-list-wrapper {
                    position: relative;
                    margin-bottom: 20px;
                    li {
                        margin-bottom: 10px;
                        position: relative;
                        padding-left: 25px;
                    }
                    li::before {
                        position: absolute;
                        content: "";
                        transform: rotate(45deg) scale(1);
                        opacity: 1;
                        left: 4px;
                        top: 4px;
                        width: 7px;
                        height: 14px;
                        border: solid #FF8000;
                        border-width: 0 2px 2px 0;
                        background-color: transparent;
                        border-radius: 0;
                    }
                }
              }
              img {
                  width: 24px;
                  -webkit-transform: rotate(-90deg);
                  transform: rotate(-90deg);  
              }
              h3 {
                  font-size: 32px;
                  line-height: 44px;
                  color: $color-blue-darkest;
                  font-weight: bold;
                    font-family: 'Sofia-Cond-Regular';
              }
              &.open {
                  h3 {
                  }
                  .accordion-content {
                    max-height: max-content;
                    padding-bottom: 20px;
                  }
                  img {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);  
                  }
              }
        }

    }

}

@media (max-width: $breakpoint-tablet) {
    .accordion-wrapper {
        .btn-orange {
            margin-bottom: 0;
        }
        .accordion-content {
            span {
                margin-top: 30px;
            }
        }
        .accordion-list > li {
            padding: 15px;
            margin-bottom: 25px;
            .accordion-title {
                height: 30px;
            }
            h3 {
                font-size: 24px;
                line-height: 33px;
            }
        }

    }
    
  }