.section-join-form {
    margin-bottom: 100px;
    text-align: center;
    .help-block {
      font-size: 12px;
      line-height: 12px;
      color: $color-red-e;
      margin-left: 5px;
      margin-top: 5px;
      position: absolute;
    }
    .checkbox-label {
      .help-block {
        left: 24px;
        margin-top: 0;
      }
    }
    h2 {
      font-size: 48px;
      line-height: 55px;
      margin-bottom: 25px;
      color:$color-blue-darkest;
      text-align: center;
    }
    p{
      color: $color-grey-p;
      margin-bottom: 30px;
    }
    .footer-form-input-container {
      border: 1px solid $color-blue-gree;
      margin-bottom: 35px;
      input {
        height: 48px;
        color: $color-blue-darkest;
        padding-left: 15px;
        top: 15px;
        font-size: 18px;
      }
      textarea {
        color: $color-blue-darkest;
        padding-left: 15px;
        top: 15px;
        font-size: 18px;
      }
      .bar:before,
      .bar::after {
        bottom: -1px;
        background: $color-blue-gree;
      }
    }
    .file-upload-wrapper.error {
      label {
        span {
          color: $color-red-e;
        }
      }
    }
    .file-upload-wrapper {
      border: 1px solid $color-blue-darkest;
      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      label {
        cursor: pointer;  
        width: 100%;
        pointer-events: all;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        left: 0;
        span {
          color: $color-blue-darkest;
          font-size: 20px;
          margin-left: 10px;
        }
      }
    }
  }
  
  
  
  @media (max-width: $breakpoint-tablet) {
    .section-join-form   {
      margin-bottom: 100px;
        .footer-form-input-container {
          margin-bottom: 20px;
        }
        .btn-orange {
          margin-top: 30px;
        }
        h2 {
            font-size: 36px;
            line-height: 45px;
        }
    }
  }