@font-face {
    font-family: 'Sofia-Cond-Regular';
    src: url(../fonts/SofiaSansCond-Regular.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Sofia-Cond-SemiBold';
    src: url(../fonts/SofiaSansCond-SemiBold.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Sofia-Cond-ExtraBold';
    src: url(../fonts/SofiaSansCond-ExtraBold.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Sofia-Cond-SemiLight';
    src: url(../fonts/SofiaSansCond-SemiLight.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Sofia-Cond-Light';
    src: url(../fonts/SofiaSansCond-Light.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Sofia-Cond-ExtraLight';
    src: url(../fonts/SofiaSansCond-ExtraLight.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Sofia-Cond-UltraLight';
    src: url(../fonts/SofiaSansCond-UltraLight.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Sofia-Regular';
    src: url(../fonts/SofiaSans-Regular.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Sofia-Medium';
    src: url(../fonts/SofiaSans-Medium.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Sofia-Bold';
    src: url(../fonts/SofiaSans-Bold.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Sofia-SemiBold';
    src: url(../fonts/SofiaSans-SemiBold.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Sofia-ExtraBold';
    src: url(../fonts/SofiaSans-ExtraBold.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'Sofia-Thin';
    src: url(../fonts/SofiaSans-Thin.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Sofia-SemiLight';
    src: url(../fonts/SofiaSans-SemiLight.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Sofia-UltraLight';
    src: url(../fonts/SofiaSans-UltraLight.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
