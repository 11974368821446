
.section-open-positions {
  margin-bottom: 100px;

  .section-join-form-wrapper {
    position: relative;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right 0% top 0%;
    min-height: 500px;
    width: 100%;
  }

  h2 {
    font-size: 48px;
    line-height: 55px;
    margin-bottom: 45px;
    color:$color-blue-darkest;
    text-align: center;
  }
}
@media (max-width: $breakpoint-tablet) {

  .section-open-positions  {
      margin-bottom: 100px;

    .section-join-form-wrapper {
      background-size:33%;
      min-height: 310px;
    }
      h2 {
          font-size: 36px;
          line-height: 45px;
      }
  }
}
@media (max-width: $breakpoint-mobile) {
    .section-open-positions  {
      .section-join-form-wrapper {
        min-height: 230px;
      }
  }
}
