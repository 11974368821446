.breadcrumb {
    .breadcrum-content {
        font-size: 18px;
        height: 90px;
        display: flex;
        align-items: center;
        padding-bottom: 5px;

        a, span{
            display: contents;
            font-size: 18px;
            color: $color-blue-darkest;
            font-family: 'Sofia-Cond-Light';
            font-weight: bold;
        }
        a {
            position: relative;
            display: block;
            margin-right: 5px;
            margin-left: 5px;
            &:first-of-type {
                margin-left: 0;
            }
            &:hover {           
                &::after {
                  
                  content: '';
                  width: 100%;
                  height: 1px;
                  background-color: $color-blue-darkest;
                  display: block;
                  position: absolute;
                }
              }
              &::after {
                
                content: '';
                position: absolute;
                bottom: -0.2rem;
                left: 0;
                width: 0%;
                height: 1px;
                background-color: $color-blue-darkest;
                -webkit-transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
                -o-transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
                transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
               }
        }
    }
}