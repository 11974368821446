.section-home-hex-1 {
  margin-bottom: 100px;
  &.section-home-news-hex-1 {
    .home-hex-more-news {
      position: relative;
      a { 
        
        position: absolute !important;
        right: 0;
        top: 50%;

      }
    }
    span {
      font-size: 18px;
      color: $color-blue-darkest;
      margin-bottom: 30px;
      display: block;
    }
  }
  .section-home-hex-1-container {
    position: relative;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right -3% top 50px;

    .home-hex-1-left-column-wrapper {
      img {
        width: 100%;
        height: 650px;
        object-fit: cover;
      }
    }

    .home-hex-1-right-column-wrapper {
      h2 {
        color: $color-blue-darkest;
        font-size: 48px;
        line-height: 55px;
        margin-bottom: 40px;
      }
      

      a {
        font-size: 20px;
        position: relative;
        color: $color-blue-darkest;
        // font-weight: bold;
        &:hover {
          &::after {
            transform: translateX(-140%);
          }
        }
        &::after {
          content: "";
          width: 40%;
          background-color: #FF8000;
          height: 3px;
          position: absolute;
          right: 0;
          bottom: -4px;
          transition: transform 0.3s ease;
          transform: translateX(0px);
        }
      }

      .right-column-article {
        box-shadow: 0px 14px 80px rgba(0, 0, 0, 0.07);
        padding: 65px 45px;
        background-color: $color-white;

        h3 {
          color: $color-blue-darkest;
          font-size: 24px;
          line-height: 33px;
          margin-bottom: 30px;
        }

        p,i,b,em,strong {
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 30px;
          color: $color-grey-p;
          font-family: 'Sofia-Regular';
        }
        ul {
          margin-bottom: 30px;
        }
        li {
          position: relative;
          left: 25px;
          margin-bottom: 0px;
        }
        li::after {
          position: absolute;
          content: "";
          transform: rotate(45deg) scale(1);
          opacity: 1;
          left: -20px;
          top: 4px;
          width: 7px;
          height: 14px;
          border: solid #FF8000;
          border-width: 0 2px 2px 0;
          background-color: transparent;
          border-radius: 0;
        }
      }
    }
  }

}

@media (max-width:1300px) {
  .section-home-hex-1 {
    .home-hex-1-right-column-wrapper {
      &>.row>div {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
      }
    }

    .section-home-hex-1-container {
      background-size: 305px;

      .home-hex-1-right-column-wrapper {
        h2 {
          font-size: 36px;
          line-height: 45px;
        }
      }
    }
  }
}

@media (max-width:$breakpoint-tablet) {

  .section-home-hex-1 {
    margin-bottom: 100px;
    &.section-home-news-hex-1 {
      .home-hex-more-news {
        position: initial;
        a {
          position: absolute !important;
          top: 4%;
          right: 15px;
          display: block;
        }
      }
    }
    .section-home-hex-1-container {
      position: relative;
      padding: 0 15px;
      background-size: 60% 40%;
      background-position: right -37% top 22%;

      .home-hex-1-left-column-wrapper {
        margin-top: 140px;
        img {
          height: 250px !important;
        }
      }

      .home-hex-1-right-column-wrapper {
        box-shadow: 0px 14px 80px rgba(0, 0, 0, 0.07);
        padding: 20px 20px 28px 20px;

        .right-column-article {
          box-shadow: none;
          padding: 0;
        }

        h2 {
          position: absolute !important;
          top: 2%;
          width: 100%;
          left: 0;
          padding: 0 15px;
        }
      }
    }

  }
}