#success-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    
    &.show {
        display: block;
    }
    .modal-dialog {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        animation: fadeNavTop 1s 1 cubic-bezier(0.77, 0, 0.175, 1);
        .modal-content {
            background-color: $color-white;
            min-width: 730px;
            text-align: center;
            position: relative;
            .modal-close {
                background-color: white;
                border: 2px solid #145571;
                position: absolute;
                width: 30px;
                height: 30px;
                right: 30px;
                top: 30px;
                font-size: 23px;
                font-family: 'Sofia-Regular';
                padding: 0;
                display: flex;
                align-items: center;
                cursor: pointer;
                overflow: hidden;
                span {
                    display: table;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    font-size: 38px;
                    color: #145571;
                }
            }
            .modal-header {
                margin: 30px 0 20px;
            }
            .modal-main {
                margin-bottom: 25px;
                h3 {
                    font-size: 36px;
                    line-height: 50px;
                    color: $color-blue-darkest;
                    font-family: 'Sofia-Cond-SemiBold';
                }
                p {
                    font-size: 18px;
                    line-height: 25px;
                    color: $color-grey-p
                }
            }
            .modal-footer {
                padding: 28px 0;
                background-color: #F3F6F8;
                position: relative;
                .btn {
                    margin: 0;
                }
                .arr-down::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    width: 0;
                    height: 0;
                    border-left: 25px solid transparent;
                    border-right: 25px solid transparent;
                    border-top: 15px solid $color-white;
                    clear: both;
                    margin: 0 auto;
                }
            }
        }
    }
}