.section-careers-header {
  margin-bottom: 50px;
  .section-careers-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
    }
    h1 {
      position: absolute;
      color: $color-white;
      font-size: 48px;
      line-height: 55px;
    }
  }
}
